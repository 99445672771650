import React, {useEffect, useState} from "react";
import {formats, LocaleProvider, useLocale} from "user/helpers/localization";
import * as Config from "user/helpers/config";
import * as Sentry from "@sentry/react";
import {ApolloClient, ApolloProvider, createHttpLink, InMemoryCache} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import {UserContextRef, UserProvider} from "../../../user/helpers/user";
import {onError} from "@apollo/client/link/error";
import {IntlProvider} from "react-intl";
import UserNav from "../../../general/react/containers/UserNav";
import {getTypePolicies} from "user/helpers/apollo";
import messagesCs from "user/translations/compiled/cs.json";

const messages = {cs: messagesCs};

export function UserMenu() {
    const [client, setClient] = useState(null);

    useEffect(() => {

        const httpLink = createHttpLink({
            uri: Config.get('graphqlUri'),
        });

        const withToken = setContext((request, previousContext) => {
            return {
                headers: {
                    authorization: 'Bearer ' + UserContextRef.current.accessToken,
                }
            }
        });

        const resetToken = onError(({networkError, graphQLErrors}) => {
            if (graphQLErrors) {
                graphQLErrors.forEach(error => {
                    Sentry.captureException(error);
                });
            }
        });

        const client = new ApolloClient({
            link: withToken.concat(resetToken).concat(httpLink),
            cache: new InMemoryCache({
                typePolicies: getTypePolicies(),
            }),
        });

        setClient(client);
    }, []);

    if (!client) {
        return null;
    }

    return(
        <LocaleProvider>
            <ApolloProvider client={client}>
                <IntlApp />
            </ApolloProvider>
        </LocaleProvider>
    );

}


function IntlApp() {
    const [locale] = useLocale();

    if (!locale) {
        return;
    }

    return (
        <IntlProvider locale={locale} messages={messages[locale]} defaultLocale={'cs'} formats={formats}>
            <UserProvider>
                <UserNav frontend={true} />
            </UserProvider>
        </IntlProvider>
    )
}