import React from 'react';
import ReactDOM from 'react-dom';
import {UserMenu} from "./UserMenu";
import * as Config from "user/helpers/config";

const userNavElement = document.getElementById('user-nav-menu');


if (userNavElement) {
    Config.initConfig(userNavElement.dataset);
    ReactDOM.render(<UserMenu />, userNavElement);
}